<template>

  <!-- id : contents : (S) -->
  <div id='contents' class="st-mypage" >

    <section class="content-wrap st-center">
      <div class="secPageTabBox " :class="{open : this.$store.state.tapOpen === true}">
        <div class="btn-go-back mb-only"><a href="#" @click.prevent="goback">이전페이지</a></div>
        <div class="sub-tab-mb-title">
          <a href="javascript:;" class="on" @click="onClickTabMobile()" >화상 회의 <i class="arrow"></i></a> 
        </div>
        <div class="sub-tab-list-box" :class="{on:this.tablistOpen === true}">
          <a href="javascript:;"><router-link to="/my/myInfo">회원정보</router-link> </a>
          <a href="javascript:;"><router-link to="/my/meetList">모임 내역</router-link></a>
          <a href="javascript:;"><router-link to="/my/videoConference">화상 회의</router-link></a>
          <a href="javascript:;"><router-link to="/my/board">내 게시글</router-link></a>
          <a href="javascript:;"><router-link to="/my/message">쪽지함</router-link></a>
          <a href="javascript:;"><router-link to="/my/inquiry">1:1 문의</router-link></a>
          <a href="javascript:;" @click="logout">로그아웃</a>
        </div>
      </div>
      <!-- class : main-wrap (S) -->
      <div class="main-wrap">
        <div class="inner-base-box mb-pd-none">

          <div class="mypage-wrap">
            <div class="aside-box">
              <div class="people-info-box">
                <div class="img-box">
                  <img class="member-list-box" v-if="userDetail.photoFile==null" src="../../../assets/front/images/icon/member.jpg"/>
                  <img class="member-list-box" v-else :src="`/attachment/user/photo/${userDetail.photoFile}`" @error="(e) => e.target.src=require('@/assets/front/images/icon/member.jpg')"/>
                </div>
                <div class="name-box">
                  <div class="name"><em>{{ userDetail.name }}</em><span>님</span></div>
                  <div class="cate">{{ userDetailDepartment }} / {{ memberClub(userDetail.clubGroup) }}</div>
                </div>
                <div class="btn-box">
                  <router-link to="/my/message">쪽지함</router-link>
                  <router-link to="/my/inquiry">1:1 문의</router-link>
                </div>
              </div>
              <div class="menu-box">
                <ul>
                  <li><router-link to="/my/myInfo">회원정보</router-link></li>
                  <li><router-link to="/my/inquiry">활동 내역</router-link>
                    <ul>
                      <li><router-link to="/my/inquiry">1:1 문의</router-link></li>
                      <li><a href="javascript:;" @click="onClickMenu">내 게시글</a></li>
                      <li><router-link to="/my/message">쪽지함</router-link></li>
                    </ul>
                  </li>
                  <li><router-link to="/my/meetList">모임 내역</router-link></li>
                  <li class="on"><router-link to="/my/videoConference">화상 회의</router-link>
                    <ul>
                      <li><router-link to="/my/videoConference">전체 회의</router-link></li>
                      <li class="on"><router-link to="/my/myConference">내가 개설한 회의</router-link></li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
            <div class="main-body-box">
              <div class="my-tit-box">내가 개설한 회의</div>
              <div class="board-search-wrap st3">
                <div class="tit-box"><router-link to="/my/videoConference" class="btn-mod-2 wd-auto">전체 회의</router-link></div>
              </div>
              <div class="tb-01 st2 st-over mb50">
                <table>
                  <colgroup><col style="width:70px"><col style="width:150px"><col style="width:*"><col style="width:110px"><col style="width:80px"></colgroup>
                  <thead>
                  <tr>
                    <th>번호</th>
                    <th>회의실 아이디</th>
                    <th>회의실 이름</th>
                    <th colspan="2">설정</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(content,idx) in contents" :key="content.roomId" class="mb-one-row">
                    <td class="al-center pc-only">{{ pagination.totalElements - ((pagination.currentPage-1) * pagination.pageSize) - idx }}</td>
                    <td class="al-center">
                      <a href="javascript:;" @click="onClickTitle(content)" class="ellipsis_inline" style="max-width: 500px">{{ content.roomId }}</a>  
                    </td>
                    <td class=" ">
                      <a href="javascript:;" @click="onClickTitle(content)" class="ellipsis_inline" style="max-width: 300px">{{ content.roomName }}</a>
                    </td>
                    <td class="al-center"><a href="javascript:;" class="a-link-line" @click="onClickInvite(content)">초대하기</a></td>
                    <td class="al-center"><a href="javascript:;" class="btnMeetingRoomInvite" @click="onClickDelete(content.roomId)">삭제</a> <!-- btnMeetingRoomInvite script 하단에 있음--></td>
                  </tr>
                  </tbody>
                </table>
              </div>
              <!-- class : pagination (S) -->
              <DgPagination v-model="pagination" @pageSelected="pageSelected"/>
              <!-- class : pagination (E) -->
            </div>
          </div>

        </div>
      </div>
      <!-- class : main-wrap (E) -->


      <div class="dg-pop-layer pop-wd-mid st-meeting-attend" id="btnMeetingRoomInviteBox">
        <div class="dg-pop-hd-st2-box st-line">
          <div class="pop-h1">회의실 수정</div>
          <div class="btn-close-box"><a href="javascript:;" class="btn-close dg-pop-close">닫기</a></div>
        </div>
        <div class="pop-cont-box ">
          <form action="">
            <div class="pop-mem-change-form">
              <div class="row-box">
                <span class="tit-box">회의실 이름</span>
                <span class="input-box">
                            <input name=""  class="input-st1 h40" value="회의실 입니다." placeholder="회의실 이름을 작성해주세요.">
                        </span>
              </div>
              <div class="row-box">
                <span class="tit-box">비밀번호</span>
                <span class="input-box st-pw-chk">
                            <input type="checkbox" class="chk-st1" id="chk0101"><label for="chk0101">회의 비공개 설정</label>
                            <input name="" class="input-st1 h40" placeholder="비밀번호">
                        </span>
              </div>
            </div>
          </form>
          <div class="board-btn-box wd150 al-center mb-none"><a href="" class="">생성</a></div>
        </div>
      </div>


    </section>
    <!-- class : content-wrap : (E) -->
  </div>
  <!-- id : contents : (E) -->

</template>

<script>
import moment from "moment";
import userApi from "@/api/UserApi";
import DgPagination from '@/views/component/DgPagination';
import hyperMeetingApi from "@/api/HyperMeetingApi";

export default {
  name: "video_index",
  created(){
    if(this.userId == '') {
      alert('로그인 후 이용하실 수 있습니다.')
      this.$router.push(`/login`)
    }else{
      this.getUserDetail();
      this.onClickSearch();
    }
  },
  components:{
    DgPagination,
  },
  props:{
    isOpen:{
      type:Boolean,
      default:false
    }
  },
  data(){
    return {
      userId: this.$store.state.user.userid,
      userDetail: {},
      userDetailDepartment: '',

      contents: [],
      pagination:{
        currentPage: 1,
        pageSize: 10,
        pageGroupSize: 10,
        totalPages:0,
        totalElements:0
      },

      param: {
        searchColumn: 'createdBy',
        searchKeyword: this.$store.state.user.userid
      },
      roomLeaderCheck:null,
      hyperUserName : null,
    }
  },
  methods: {
    getUserDetail(){
      userApi.get(this.userId).then((data) =>{
        this.userDetail = data
        this.userDetailDepartment = data.departmentGroup[0].groupName
      })
    },
    memberClub(val){
      if(val != null) {
        let clubLength = val.length
        let userClubs = clubLength > 0 ? val[0].groupName : ''
        if (clubLength > 1) {
          userClubs += " 외 " + (clubLength - 1)
        }
        return userClubs
      }else{
        return '';
      }
    },
    onClickSearch(){
      let date = new Date()
      let params = {...this.param, ...{
          page: this.pagination.currentPage,
          size: this.pagination.pageSize,
          fromDate:moment(new Date(date.setDate(date.getDate() - 7))).format('YYYY-MM-DD'),
          toDate:moment(new Date()).format('YYYY-MM-DD')
        }};

      hyperMeetingApi.list(params).then((data)=>{
        this.contents = data.content;

        this.pagination.totalElements=data.totalElements
        let totalPages = Math.floor(data.totalElements / this.pagination.pageSize) + ((data.totalElements % this.pagination.pageSize) > 0 ? 1 : 0) ;
        this.pagination.totalPages = totalPages;
      })
    },
    pageSelected(pagination){
      this.pagination.currentPage = pagination.currentPage;
      this.onClickSearch();
    },
    async onClickTitle(item){
      let today = new Date();
      this.hyperUserName = this.$store.state.user.username;
      if(item.roomState == 'STATE_OPENED') {
        // let roomUrl = `https://hypermeeting.biz/meeting/${item.roomId}?userId=${this.$store.state.user.userid}&userName=`+this.hyperUserName
        let roomUrl = `https://hypermeeting.biz/meeting/${item.roomId}?userId=${this.$store.state.user.userid}&userName=${this.$store.state.user.username}`
        await hyperMeetingApi.exist(item.roomId).then(data=>{
          this.roomLeaderCheck = data.data.leaderJoined;
        })

        console.log("this.roomLeaderCheck : " + this.roomLeaderCheck)
        if (item.createdBy === this.$store.state.user.userid && this.roomLeaderCheck===false) {
          roomUrl += `&leaderKey=${item.leaderKey}`
          if (item.hasPassword) {
            roomUrl += `&password=${item.roomPassword}`
          }
          window.open(roomUrl, '_new');
        } else {
          if (item.hasPassword) {
            this.onClickEnterPopup(true);
            this.roomInfo = item;
          }else {
            if(item.createdBy === this.$store.state.user.userid){
              this.hyperUserName = this.hyperUserName+'PC:'+today.getMinutes()+':'+today.getSeconds();
              roomUrl = `https://hypermeeting.biz/meeting/${item.roomId}?userId=${this.$store.state.user.userid}&userName=`+this.hyperUserName
            }
            window.open(roomUrl, '_new');
          }
        }
      }else{
        alert('종료된 회의입니다.')
      }
    },
    onClickDelete(val){
        if(confirm('회의실 개설 내역을 삭제하시겠습니까?')) {
          hyperMeetingApi.deleteRoom(val).then(()=>{
              this.pagination.currentPage = 1;
              this.onClickSearch();
            })
        }
    },
    onClickMenu(){
      this.contents= [],
      this.pagination= {
        currentPage: 1,
        pageSize: 10,
        pageGroupSize: 10,
        totalPages: 0,
        totalElements: 0
      },
      this.searchColumn='boardName',
      this.searchKeyword=''

      this.onClickSearch();
    },
    onClickInvite(val){
      if(val.roomState == 'STATE_OPENED') {
        this.$router.push(`/my/videoInvite/` + val.roomId);
      }else{
        alert('종료된 회의입니다.')
      }
    },
    goback(){
      this.$router.go(-1);
    },
    logout() {
      this.$store.dispatch('user/logout');
      window.location.href='/'
    },
  }
}
</script>

<style scoped>

</style>